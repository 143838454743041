<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Hệ đào tạo"
                    label-for="trainingSystem"
                  >
                    <v-select
                      id="trainingSystem"
                      v-model="filter.trainingSystemId"
                      label="name"
                      :options="trainingSystems"
                      :reduce="(option) => option.id"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Khóa học"
                    label-for="course"
                  >
                    <v-select
                      id="course"
                      v-model="filter.courseId"
                      label="name"
                      :options="courses"
                      :reduce="(option) => option.id"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Học kỳ"
                    label-for="semester"
                  >
                    <v-select
                      id="semester"
                      v-model="filter.courseSemesterId"
                      label="name"
                      :options="semesters"
                      :reduce="(option) => option.id"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Học phần"
                    label-for="subject"
                  >
                    <v-select
                      id="subject"
                      v-model="filter.subjectId"
                      label="name"
                      :options="subjects"
                      :reduce="(option) => option.id"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Lớp độc lập"
                    label-for="creditClass"
                  >
                    <v-select
                      id="creditClass"
                      v-model="filter.creditClassId"
                      label="code"
                      :options="creditClasses"
                      :reduce="(option) => option.id"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="w-100 d-flex justify-content-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="getDataSourcesFromStore"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="SearchIcon" /> Tìm kiếm
                      </span>
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      @click="exportExcel"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="DownloadIcon" /> Xuất excel
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :line-numbers="true"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị 1 đến </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import { Flag } from '@/const/flag'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'ReportCreditClassBySubject',
  directives: {
    Ripple,
  },
  components: {
    BOverlay,
    BPagination,
    vSelect,
    BFormSelect,
    BFormGroup,
    BCol,
    BContainer,
    BButton,
    BForm,
    BCard,
    BRow,
    VueGoodTable,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        itemsPerPage: 20,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        subjectId: null,
        creditClassId: null,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      currentCreditClassId: null,
      dataSources: [],
      currentPoint: null,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'creditClassBySubject/trainingSystems',
      courses: 'creditClassBySubject/courses',
      semesters: 'creditClassBySubject/semesters',
      subjects: 'creditClassBySubject/subjects',
      creditClasses: 'creditClassBySubject/creditClasses',
      learningResult: 'creditClass/learningResult',
      dataExport: 'creditClass/dataExport',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.filter.itemsPerPage,
      }
    },
    columns() {
      let data = [
        {
          label: 'Mã sinh viên',
          field: 'studentCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã sinh viên',
          },
          sortable: true,
          hidden: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập họ',
          },
          sortable: true,
          hidden: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên',
          field: 'firstName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên',
          },
          sortable: false,
          hidden: false,
          thClass: 'text-center',
        },
      ]
      if (this.learningResult.length > 0) {
        const dataSource = this.learningResult[0]
        if (dataSource.scores.length > 0) {
          dataSource.scores.forEach(score => {
            data = [...data, {
              label: score.name,
              field: score.code,
              hidden: false,
              sortable: false,
              thClass: 'text-center',
              tdClass: 'text-center',
            }]
          })
        }
      }
      data = [...data, {
        label: 'Ghi chú',
        field: 'firstName',
        hidden: true,
        sortable: false,
        thClass: 'text-center',
        tdClass: 'text-center',
      }]
      return data
    },
  },
  watch: {
    'filter.trainingSystemId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getCoursesByTrainingSystemId({ trainingSystemId: val })
          if (this.courses.length > 0) {
            this.updateParams({ courseId: this.courses[0].id })
          } else {
            this.updateParams({
              courseId: null,
              courseSemesterId: null,
              subjectId: null,
              creditClassId: null,
            })
            this.setSemesters([])
            this.setSubjects([])
            this.setCreditClasses([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({
          courseId: null,
          courseSemesterId: null,
          subjectId: null,
          creditClassId: null,
        })
        this.setCourses([])
        this.setSemesters([])
        this.setSubjects([])
        this.setCreditClasses([])
      }
    },
    'filter.courseId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getSemestersByCourseId({ courseId: val })
          if (this.semesters.length > 0) {
            const found = this.semesters.find(semester => semester.status === Flag.ACTIVE)
            if (found) {
              this.updateParams({ courseSemesterId: found.id })
            } else {
              this.updateParams({ courseSemesterId: this.semesters[0].id })
            }
          } else {
            this.updateParams({
              courseSemesterId: null,
              subjectId: null,
              creditClassId: null,
            })
            this.setSubjects([])
            this.setCreditClasses([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({
          courseSemesterId: null,
          subjectId: null,
          creditClassId: null,
        })
        this.setSemesters([])
        this.setSubjects([])
        this.setCreditClasses([])
      }
    },
    'filter.courseSemesterId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getSubjectsByCourseSemesterId({ courseSemesterId: val })
          if (this.subjects.length > 0) {
            this.updateParams({ subjectId: this.subjects[0].id })
          } else {
            this.updateParams({ subjectId: null, creditClassId: null })
            this.setCreditClasses([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({ subjectId: null, creditClassId: null })
        this.setSubjects([])
        this.setCreditClasses([])
      }
    },
    'filter.subjectId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getCreditClassesByCourseSemesterAndSubject({
            courseSemesterId: this.filter.courseSemesterId,
            subjectId: val,
            status: Flag.CREDIT_CLASS_STUDYING,
            type: 'greaterThanEqual',
          })
          if (this.creditClasses.length > 0) {
            this.updateParams({ creditClassId: this.creditClasses[0].id })
          } else {
            this.updateParams({ creditClassId: null })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({ creditClassId: null })
        this.setCreditClasses([])
      }
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({
        organizationId: this.user.orgId,
      })
      if (this.trainingSystems.length > 0) {
        this.updateParams({ trainingSystemId: this.trainingSystems[0].id })
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setTrainingSystems: 'creditClassBySubject/SET_TRAINING_SYSTEMS',
      setCourses: 'creditClassBySubject/SET_COURSES',
      setSemesters: 'creditClassBySubject/SET_SEMESTERS',
      setSubjects: 'creditClassBySubject/SET_SUBJECTS',
      setCreditClasses: 'creditClassBySubject/SET_CREDIT_CLASSES',
      setDataSources: 'creditClass/SET_LEARNING_RESULT',
    }),
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'creditClassBySubject/getTrainingSystemsByOrganizationId',
      getCoursesByTrainingSystemId: 'creditClassBySubject/getCoursesByTrainingSystemId',
      getSemestersByCourseId: 'creditClassBySubject/getSemestersByCourseId',
      getSubjectsByCourseSemesterId: 'creditClassBySubject/getSubjectsByCourseSemesterId',
      getLearningResult: 'creditClass/getLearningResult',
      getDataExport: 'creditClass/downloadExport',
      getCreditClassesByCourseSemesterAndSubject: 'creditClassBySubject/getCreditClassesByCourseSemesterAndSubject',
    }),
    reset() {
      this.updateParams({
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        subjectId: null,
        creditClassId: null,
      })
      this.setTrainingSystems([])
      this.setCourses([])
      this.setSemesters([])
      this.setSubjects([])
      this.setCreditClasses([])
      this.setDataSources([])
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    convertData() {
      const data = []
      if (this.learningResult.length === 0) return data
      this.learningResult.forEach(result => {
        const { scores, ...rest } = result
        if (scores.length > 0) {
          this.scoreQuantity = scores.length
          let element = { ...rest }
          scores.forEach(score => {
            const sc = {}
            sc[`id${score.code}`] = score.id
            sc[score.code] = score.numberValue
            element = { ...element, ...sc }
          })
          data.push(element)
        }
      })
      return data
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getLearningResult({ creditClassId: this.filter.creditClassId })
        this.dataTable = this.convertData()
        this.dataSources = this.convertData()
        this.originScores = this.dataSources.map(elm => ({ ...elm }))
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportExcel() {
      await this.getDataExport({ creditClassId: this.filter.creditClassId })
      const a = document.createElement('a')
      document.body.appendChild(a)
      const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = 'ket_qua_hoc_tap.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
